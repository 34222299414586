import * as React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import "../css/styles.css"

// markup
const IndexPage = ({pageContext}) => {
  let name;
  if (pageContext.name !== undefined) {
     name = <h3>Hi {pageContext.name}!</h3>
  }

  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ian & Desta's Wedding</title>
          <link rel="canonical" href="https://rollmuttwedding.co.uk" />
          <link href='//fonts.googleapis.com/css?family=Roboto+Slab:700,400|Dancing+Script:700' rel='stylesheet' type='text/css'/>
          <script src={withPrefix('scripts.js')} type="text/javascript" />
        </Helmet>
        <div className="title">
          <h1>Ian &amp; Desta's Wedding</h1>
          {name}    
        </div>

        <div className="wrapper">

            
            <div className="content">
                    <article>
        <header>
            <img src="https://s3-eu-west-1.amazonaws.com/www.rollmuttwedding.co.uk/uploads/item/image/1/iandesta.jpg" alt="Iandesta" />
            <h3>The Happy Couple</h3>
        </header>
        <p>Ian James Rollins and Desta Ria Mutter met 27th July 2009 &amp; married on 26th June 2015.</p>
    </article>
    <article>
        <header>
            <img src="https://s3-eu-west-1.amazonaws.com/www.rollmuttwedding.co.uk/uploads/item/image/7/photos.jpg" alt="Photos" />
            <h3>Photos</h3>
        </header>
        <p>The Official Wedding photos are now available in this <a href="https://goo.gl/photos/sLdtGUbpS4pUVwMy6">Google Photos album</a>.  </p><p>Friends &amp; Family photos are in this <a href="https://goo.gl/photos/3wZXPxCNbGFP2ruF7">Google Photos album</a>.</p>
    </article>
    <article>
        <header>
            <img src="https://s3-eu-west-1.amazonaws.com/www.rollmuttwedding.co.uk/uploads/item/image/2/tbridge.jpg" alt="Tbridge" />
            <h3>Time &amp; Place</h3>
        </header>
        <p>Friday 26th June 2015, 19:00. </p><p>The Walkways, Tower Bridge.</p>
    </article>

            </div>
            
        </div>
    </div>
  )
}

export default IndexPage
